<template>
  <div>
    <Modal :options="{width: '40vw', close: true}" @close="$emit('close')">
      <div class="title">Agregar comentario</div>
      <div class="body">
        <div class="row form-group">
          <label for="etapa" class="col-form-label col-sm-3">Etapa</label>
          <div class="col-sm-9">
            <select v-model="etapa" name="etapa" id="etapa" class="form-control">
              <option value="callback">CallBack</option>
              <option value="opinion">Opinión de Valor</option>
              <option value="citas">Preautorización</option>
              <option value="entrevistas">Documentación</option>
              <option value="comite">Autorización</option>
              <option value="ejercido">Ejercido</option>
              <option value="desarrollo">Desarrollo Empresarial</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="observacion" class="col-form-label col-sm-3">Observación</label>
          <div class="col-sm-9"><textarea v-model="observacion" name="observacion" id="observacion" cols="30" rows="7" class="form-control"></textarea></div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="agregar_observacion">Agregar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/Modal'

  import api from '@/apps/solicitudes/api/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
    }
    ,data() {
      return {
        etapa: 'callback',
        observacion: null
      }
    }
    ,methods: {
      async agregar_observacion() {
        try {
          if (!this.observacion)
            return this.$helper.showMessage('Error','Debes definir la observación','error','alert');

          let res = (await api.agregar_observacion(this.solicitud.id, {
            etapa: this.etapa,
            observacion: this.observacion
          })).data;
          this.$log.info('res', res);
          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>